export function usePartPayment(price: Ref<number>) {
  /**
   *  Korko: 12,9%
   *  3kk Euribor: ?,?%
   *  Alle 500 € ostos – avausmaksu 4,90 € ja käsittelykulu 0 € /kk
   *  500-1000 € ostos – avausmaksu 0 € ja käsittelykulu 1,50 € /kk
   *  1000-2000 € ostos – avausmaksu 0 € ja käsittelykulu 3 € /kk
   *  2000 -> €  ostos – avausmaksu 0 € ja käsittelykulu 6 € /kk
   */
  const euribor = 2.293 // This value changes every 3 months. Should it be configurable somewhere or should the interest rate be fetched from Svea API?
  const interestRate = 16.207 + euribor
  const paymentPeriodMonths = 36
  const minTotalPriceForPartPayment = 50
  const maxTotalPriceForPartPayment = 6000
  const monthlyInterestRate = (interestRate / 100) / 12
  const monthlyFee = computed(() => (price.value < 500) ? 0 : (price.value >= 500 && price.value < 1000) ? 1.5 : (Math.floor(price.value / 1000)) <= 2 ? (Math.floor(price.value / 1000)) * 3 : 6)

  const partPaymentMonthlyAnnuity
    = computed(() => ((price.value * monthlyInterestRate * Math.pow((1 + monthlyInterestRate), paymentPeriodMonths)) / (Math.pow((1 + monthlyInterestRate), paymentPeriodMonths) - 1)))

  const monthlyPayment = computed(() => (partPaymentMonthlyAnnuity.value + monthlyFee.value).toFixed(2))
  const canBePaidWithPartPayment = computed(() => price.value > minTotalPriceForPartPayment && price.value < maxTotalPriceForPartPayment)
  const canBePaidWithInvoiceAndPartPayment = computed(() => price.value < maxTotalPriceForPartPayment)

  return { canBePaidWithPartPayment, canBePaidWithInvoiceAndPartPayment, monthlyPayment }
}
