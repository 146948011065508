<template>
  <div class="min-h-72 md:min-h-48">
    <div
      class="svea-pp-widget-part-payment"
      :data-price="price"
      data-sellerid="IPREGETJ"
      data-maksuturva-host="https://www.maksuturva.fi/"
      data-threshold-prices="[]"
      data-active-color="#AE0F0A"
      data-text-color="#000000"
      data-highlight-color="#000000"
      data-border-color="#D4D4D4"
      data-border-radius="0"
      data-locale="fi"
    />
  </div>
</template>

<script setup lang="ts">
defineProps({
  price: {
    type: Number,
    required: true
  }
})

onMounted(async () => {
  await nextTick()
  await new Promise(resolve => setTimeout(resolve, 250))
  // @ts-ignore
  window?.SveaPartPayment?.initializeWidgets()
})

useHead(
  {
    script: [{
      hid: 'svea-part-payment-widget',
      type: 'text/javascript',
      src: 'https://payments.maksuturva.fi/tools/partpayment/partPayment.js',
      defer: true
    }]
  }
)
</script>
